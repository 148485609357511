import React from "react";

import { AppBar, Box } from "@mui/material";

import NavigationMenu from "./NavigationMenu";
import { BACKGROUND } from "theme/colors";

const Header = () => {
  return (
    <Box display="block" mb={6.5}>
      <AppBar
        position="fixed"
        // sx={{ backgroundColor: "background.white" }}
        sx={{ backgroundColor: BACKGROUND.ntzBlack }}
      >
        <NavigationMenu />
      </AppBar>
    </Box>
  );
};

export default Header;
