import React, { Children, useEffect, useRef } from "react";
import { Box, Typography, Avatar, Paper, Chip, IconButton, Link, Divider } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import SearchBar from "./SearchBar";
import T from "T";
import { Adb, ThumbDownAltOutlined, ThumbUpOffAltOutlined } from "@mui/icons-material";
const InputUI = ({
  isLoading,
  searchInputPrompt,
  savedSearchPrompt,
  promptResponse,
  handlesearchInputPromptChange,
  handlePromptSubmit,
  handleThumbDown,
  handleThumbUp,
}) => {
  const boxRef = useRef(null);
  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  }, [savedSearchPrompt]);
  return (
    <>
      <Box sx={{ padding: "0px 10%", height: "calc(100vh - 150px)", overflowY: "auto" }} ref={boxRef}>
        <Box
          sx={{
            height: "calc(100vh - 260px)",
            // overflowY: "",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage:
                "url(https://darwinbox-data-prod-mum.s3.ap-south-1.amazonaws.com/INSTANCE5_a6280ead088c4f_173/workflows/a175460077864dddaf331682__1692261107_4403555%20%281%29.jpg)",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              opacity: 0.1, // Adjust the opacity as needed (0 to 1)
              // zIndex: 0, // Behind the other content
              pointerEvents: "none",
            },
          }}
        >
          {Children.toArray(
            savedSearchPrompt.map((data, index) => {
              const isUser = data.id === "user";
              return (
                <Paper
                  sx={{
                    display: "flex",
                    margin: "10px 0px",
                    padding: "10px",
                    wordBreak: "break-word",
                    bgcolor: !isUser ? "background.card" : "",
                  }}
                >
                  <Avatar variant="rounded" sx={{ bgcolor: isUser ? "themeColor" : "background.ntzBlack" }}>
                    {isUser ? <PersonIcon /> : <Adb />}
                  </Avatar>
                  {/* <Avatar>
                                    <img  src={NETSMARTZ_LOGO_SRC} style={{height:"40px",width:"40px"}} alt="Netsmartz Logo" 
                                    />
                                </Avatar> */}
                  {/* <Box sx={{marginLeft:"20px"}}>
                                <ReactTyped
                                        strings={[data.query]}
                                        
                                        typeSpeed={10}
                                        // loop
                                        // backSpeed={20}
                                        // cursorChar=">"
                                        // showCursor={true}
                                        />
                                </Box> */}
                  <Box display={"block"} sx={{ marginLeft: "20px", width: "100vw" }}>
                    <Typography
                      variant="h6"
                      sx={{
                        flexWrap: "wrap",
                        textAlign: "start",
                      }}
                    >
                      {data.query}
                    </Typography>
                    {!isUser && data.links && (
                      <Box sx={{ marginTop: 1.5 }}>
                        <Typography variant="ntz_subtitle">
                          For More information please refer to these policy documents:
                        </Typography>
                        {data.links.map((item) => (
                          <Box display={"inline-flex"}>
                            <Link
                              variant="ntz_subtitle"
                              href={item.href}
                              target={"_blank"}
                              sx={{ padding: 1, color: "themeColor", textDecoration: "none" }}
                            >
                              {item.label}{" "}
                            </Link>

                            <Divider flexItem variant="middle" orientation="vertical" />
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                  {data.id === "user" ? (
                    <Chip
                      label={data.department}
                      sx={{
                        bgcolor: "border.tabsGrey",
                        color: "text.secondary",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    />
                  ) : (
                    <Box display={"inline-flex"} alignItems={"flex-start"}>
                      <IconButton
                        onClick={() => handleThumbUp(index)}
                        // disabled={data.feedBack === T.GOOD}
                        color={data.feedBack === T.GOOD ? "success" : "default"}
                      >
                        <ThumbUpOffAltOutlined fontSize="small" />
                      </IconButton>
                      <IconButton
                        onClick={() => handleThumbDown(index)}
                        // disabled={data.feedBack === T.BAD}
                        color={data.feedBack === T.BAD ? "error" : "default"}
                      >
                        <ThumbDownAltOutlined fontSize="small" />
                      </IconButton>
                    </Box>
                  )}
                </Paper>
              );
            })
          )}
        </Box>
      </Box>
      <Box padding="0px 10%">
        <SearchBar
          isLoading={isLoading}
          searchInputPrompt={searchInputPrompt}
          handleChange={handlesearchInputPromptChange}
          handleSubmit={handlePromptSubmit}
          placeholder={T.SEND_A_MESSAGE}
        />
      </Box>
    </>
  );
};

export default InputUI;
