import api from ".";

import Constants from "../Constants";

const { SERVER_URL } = Constants;

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSessionToken: builder.query({
      query: () => ({
        url: `${SERVER_URL}/token`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetSessionTokenQuery } = extendedApi;
