import Constants from "Constants";
import api from ".";
const { FAQ_SERVER_URL } = Constants;

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    postQuery: build.mutation({
      query: ({query_str}) => ({
        url: `${FAQ_SERVER_URL}/query?query_str=${query_str}`,
        method: "POST",
      }),
    }),
  }),
});

export const { usePostQueryMutation } = extendedApi;

