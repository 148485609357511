/* eslint-disable */

export default class T {
  static FOOTER_TEXT = "Enter The Footer Text Here";
  static FAQ_CHATBOT = "FAQ ChatBot";
  static PLEASE_ENTER_SOMETHING = "Please Enter Something";
  static SEND_A_MESSAGE = "Send a message";
  static HR = "HR";
  static IT = "IT";
  static FINANCE = "Finance";
  static ACCOUNTS = "Accounts";
  static QUERY_RELATED = "Query Related";
  static LEAVE_RESPONSE_MESSAGE = "Don't forget to leave a response!! (Thumbs up/Thumbs down)";
  static GOOD = "Good";
  static BAD = "Bad";
  static SOURCE_DOCS = "Source documents";
}
