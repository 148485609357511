import { useEffect, useReducer, useState } from "react";
import InputUI from "./InputUI";
import { toast } from "react-toastify";
import T from "T";
import { Popper, Fade, Paper, Typography, styled } from "@mui/material";
import { useSaveInteractionMutation } from "api/saveInteraction";
import useSessionStorage from "utils/hooks/useSessionStorage";
import { errorHandler } from "utils/errorHandler";
import { usePostQueryMutation } from "api/postQuery";
import { shallowEqual, useSelector } from "react-redux";
import { get } from "utils/lodash";
import { useDispatch } from "react-redux";
import { addFeedback, addLinks, saveChat } from "slices/homeSlice";
import { isEqual } from "lodash";

const StyledHeading = styled(Typography)((props) => ({
  color: props.theme.palette.themeColor,
  fontWeight: 800,
}));

const StyledTypography = styled(Typography)((props) => ({
  cursor: "pointer",
  "&:hover": {
    color: props.theme.palette.themeColor,
    fontWeight: "bold",
  },
}));

const ChatBot = () => {
  const dispatch = useDispatch();
  // const [chatHistory, setChatHistory] = useState([]);
  const [saveInteraction, { isLoading: isSavingToDB }] = useSaveInteractionMutation();
  const [postQuery, { isLoading: isGettingBotResponse }] = usePostQueryMutation();
  const { getItem } = useSessionStorage();
  const sessionToken = getItem("token");

  const { chatHistory: persistedChat } = useSelector(
    (state) => ({
      chatHistory: get(state, "homeSlice.chatHistory", []),
    }),
    shallowEqual
  );

  // useEffect(() => {
  //   if (Array.isArray(chatHistory) && chatHistory.length > 0) {
  //     dispatch(saveChat({ chatHistory }));
  //   }
  // }, [chatHistory]);

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    searchInputPrompt: "",
    promptResponse: "",
    openPopper: false,
    anchorEl: null,
  });
  useEffect(() => {
    const defaultMessage = {
      id: "bot",
      query:
        "Hi, Welcome to Netsmartz FAQ Chatbot. You may ask any question related to HR, IT or Finance. What would you like to know today?",
      department: null,
      feedBack: null,
    };
    // setChatHistory([defaultMessage]);
    dispatch(saveChat({ chat: defaultMessage }));
  }, []);
  const { searchInputPrompt, promptResponse, openPopper, anchorEl } = localState;

  const handlesearchInputPromptChange = (e) => {
    const { name, value } = e.target;
    setLocalState({ [name]: value, openPopper: false });
  };

  const handleDeptSelect = (val) => {
    setLocalState({
      searchInputPrompt: "",
      openPopper: !openPopper,
      department: val,
    });
    const userPrompt = {
      id: "user",
      query: searchInputPrompt,
      department: val,
      feedBack: null,
    };
    // setChatHistory((prevChat) => [...prevChat, userPrompt]);
    dispatch(saveChat({ chat: userPrompt }));

    getBotResponse(searchInputPrompt, val);
  };

  const handlePromptSubmit = (e) => {
    if (searchInputPrompt) {
      setLocalState({ openPopper: !openPopper, anchorEl: e.currentTarget });
    } else {
      toast.error(T.PLEASE_ENTER_SOMETHING);
    }
  };

  const getBotResponse = (prompt, department) => {
    postQuery({ query_str: prompt })
      .unwrap()
      .then((res) => {
        const botResp = {
          id: "bot",
          query: res.response.split("\n").map((item, index) => <div key={index}>{item}</div>),
          department: null,
          feedBack: null,
        };
        dispatch(saveChat({ chat: botResp }));
        // setChatHistory((prevChat) => [...prevChat, botResp]);
        const body = {
          token: sessionToken,
          question: prompt,
          answer: res.response,
          file_details_Id: res[T.SOURCE_DOCS],
          department: department,
        };
        saveInteraction(body)
          .unwrap()
          .then((res) => {
            if (res.data.faqLog.file_details_Id && res.data.faqLog.file_details_Id.length > 0) {
              const resLinks = [];
              for (let i of res.data.faqLog.file_details_Id) {
                resLinks.push({ label: i.document_name, href: i.document_link });
              }
              dispatch(addLinks({ links: resLinks }));
            }
          })
          .catch(errorHandler);
      })
      .catch(errorHandler);
  };

  const handleThumbDown = (index) => {
    // const chatCopy = [...chatHistory];
    // chatCopy[index].feedBack = T.BAD;
    // setChatHistory(chatCopy);
    dispatch(addFeedback({ index, feedBack: T.BAD }));
  };

  const handleThumbUp = (index) => {
    // const chatCopy = [...chatHistory];
    // chatCopy[index].feedBack = T.GOOD;
    // setChatHistory(chatCopy);
    dispatch(addFeedback({ index, feedBack: T.GOOD }));
  };

  return (
    <>
      <InputUI
        isLoading={isGettingBotResponse}
        searchInputPrompt={searchInputPrompt}
        savedSearchPrompt={persistedChat}
        promptResponse={promptResponse}
        handlesearchInputPromptChange={handlesearchInputPromptChange}
        handlePromptSubmit={handlePromptSubmit}
        handleThumbDown={handleThumbDown}
        handleThumbUp={handleThumbUp}
      />

      <Popper open={openPopper} anchorEl={anchorEl} placement="top-end" transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ padding: "10px" }}>
              <StyledHeading>{`${T.QUERY_RELATED}:`}</StyledHeading>
              <StyledTypography onClick={() => handleDeptSelect(T.HR)}>{T.HR}</StyledTypography>
              <StyledTypography onClick={() => handleDeptSelect(T.IT)}>{T.IT}</StyledTypography>
              <StyledTypography onClick={() => handleDeptSelect(T.FINANCE)}>{T.FINANCE}</StyledTypography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default ChatBot;
