import api from ".";
import Constants from "../Constants";

const { SERVER_URL } = Constants;

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveInteraction: build.mutation({
      query: (body) => ({
        url: `${SERVER_URL}/logInteractions`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useSaveInteractionMutation } = extendedApi;
