import PropTypes from "prop-types";
import noop from "lodash/noop";
import SendIcon from "@mui/icons-material/Send";

import FAQTextField from "components/common/FAQTextField";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";

const SearchBar = ({
  isLoading,
  records = {},
  searchInputPrompt = "",
  placeholder = "",
  showOptions = false,
  showCancelIcon = false,
  onClick = noop,
  handleSubmit = noop,
  handleChange = noop,
  reset = noop,
  onClickOutside = noop,
}) => {
  return (
    <FAQTextField
      value={searchInputPrompt}
      name="searchInputPrompt"
      fullWidth
      // multiline
      size="medium"
      sx={{
        backgroundColor: "background.white",
        "& .MuiOutlinedInput-input": {
          fontSize: 22,
        },
        "& .MuiOutlinedInput-root": {
          boxShadow: 3, // Adjust values as needed
          borderRadius: "8px",
          paddingRight: "0px",
        },
      }}
      placeholder={placeholder}
      onChange={handleChange}
      onKeyDown={(e) => {
        if (e.key !== "Enter" || e.shiftKey || !searchInputPrompt || isLoading) {
          return;
        }
        e.preventDefault();
        handleSubmit(e);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isLoading ? (
              <IconButton>
                <CircularProgress size={20} sx={{ color: "themeColor", mr: 1 }} />
              </IconButton>
            ) : (
              <IconButton onClick={handleSubmit} disabled={!searchInputPrompt} sx={{ color: "themeColor", mr: 1 }}>
                <SendIcon fontSize="small" name="sendIcon" data-val={searchInputPrompt} />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

SearchBar.propTypes = {
  searchedTableData: PropTypes.object,
  searchInputPrompt: PropTypes.string,
  showOptions: PropTypes.bool,
  showCancelIcon: PropTypes.bool,
  onClick: PropTypes.func,
  handleChange: PropTypes.func,
  handleKeyChange: PropTypes.func,
  reset: PropTypes.func,
  onClickOutside: PropTypes.func,
};

export default SearchBar;
