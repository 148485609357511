const APP_PREFIX = "/app";

const routes = {
  app: {
    home: `${APP_PREFIX}/home`,
    noAuth: `${APP_PREFIX}/noAuth`,
    notFound: `${APP_PREFIX}/404`,
  },
};

export default routes;
