import { get } from "lodash";
import React, { useEffect } from "react";
import ChatBot from "components/ChatBot";
import { toast } from "react-toastify";
import T from "T";
import { useLazyGetSessionTokenQuery } from "api/getSessionToken";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./index.css";
import Constants from "Constants";
const { WEBCHAT_SECRET_KEY } = Constants;
const HomeIndex = () => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const [ getSessionToken ] = useLazyGetSessionTokenQuery();
  // const handleSessionTokenSetting=()=>{
  //   getSessionToken()
  //   .unwrap()
  //   .then(res=>{
  //     const token=get(res,"token","");
  //     sessionStorage.setItem("sessionToken",token);
  //   })
  // }

  useEffect(() => {
    toast.success(T.LEAVE_RESPONSE_MESSAGE);
    // handleSessionTokenSetting();
  }, []);
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        {/* <TabContext value={value}>
          <Box sx={{ borderColor: "divider", display: "flex", justifyContent: "center" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example" indicatorColor="none" sx={{ mt: 1 }}>
              <Tab label="Netsmartz Bot" value="1" />
              <Tab label="Azure Bot" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1"> */}
        <ChatBot />
        {/* </TabPanel>
          <TabPanel value="2">
            <iframe
              title="chatbot"
              src={`https://webchat.botframework.com/embed/bot-gen-ai-bot?s=${WEBCHAT_SECRET_KEY}`}
              className={"chatbot"}
            ></iframe>
          </TabPanel>
        </TabContext> */}
      </Box>
    </>
  );
};

export default HomeIndex;
